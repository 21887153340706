// import { lazy } from 'react'
import asyncComponent from "../components/AsyncComponent";

// import Home from '../pages/Home'
// import About from '../pages/About'
import NoPage from '../pages/404'
// import Contact from '../pages/Contact'
import Jobs from '../pages/Jobs'
import SingleJob from '../pages/SingleJob'
import Apply from "../pages/Apply"
// import RightFit from "../pages/RightFit"
// import WhoHiring from "../pages/WhoHiring"
// import OurPassion from "../pages/OurPassion"

import { PageTemplate } from '../pages/Template'

const AsyncHome = asyncComponent(() => import("../pages/Home"));
const AsyncAbout = asyncComponent(() => import("../pages/About"));
const AsyncContact = asyncComponent(() => import("../pages/Contact"));
//const AsyncJobs = asyncComponent(() => import("../pages/Jobs"));
const AsyncNews = asyncComponent(() => import("../pages/News"));
//const AsyncSingleJob = asyncComponent(() => import("../pages/SingleJob"));
// const AsyncApply = asyncComponent(() => import("../pages/Apply"));
const AsyncRightFit = asyncComponent(() => import("../pages/RightFit"));
const AsyncWhoHiring = asyncComponent(() => import("../pages/WhoHiring"));
const AsyncOurPassion = asyncComponent(() => import("../pages/OurPassion"));


var indexRoutes = [
    { path: "/", name: "Home", exact: true, component: AsyncHome },
    { path: "/about-us/", name: "About Us", component: AsyncAbout },
    { path: "/404/", name: "404", component: NoPage },
    { path: "/the-right-fit/", name: "The Right Fit", component: AsyncRightFit },
    { path: "/whos-hiring/", name: "Who's Hiring", component: AsyncWhoHiring },
    { path: "/our-passion/", name: "Our Passion", component: AsyncOurPassion },
    { path: "/contact-us/", name: "Contact Us", component: AsyncContact },
    { path: "/apply-now/", name: "Apply Now", component: Apply },
    { path: "/find-jobs/", exact: true, name: "Jobs", component: Jobs },
    { path: "/news/", exact: true, name: "News", component: AsyncNews},
    { path: "/news/:slug", name: "News Item", component: PageTemplate },
    { path: "/job/:slug", name: "Single Job", component: SingleJob },
    { path: "/:slug", name: "Template", component: PageTemplate },
    { path: "*", name: "404", component: NoPage },
  ];
  
export default indexRoutes;