import React, { Component } from 'react'
import { createBrowserHistory } from "history"
import { Router, Route, Switch } from "react-router-dom"
import indexRoutes from "./Routes"
import Wrapper from "./components/Wrapper"
import ScrollToTop from './extra/ScrollToTop'
import BgLoader from "./tools/BgLoader"

// import Loading from './elements/Loading'
// import Img from 'react-image'
// import largeLogo from './images/logolr.svg'


import './style.sass'
import './extra/scripts'

var hist = createBrowserHistory()



export default class App extends Component {

    render() {
        return (
            <Router history={hist}>
                <ScrollToTop />
                <BgLoader />
                <Wrapper>
                <Switch>
                {indexRoutes.map((prop, key) => {

                    if(prop.exact) {
                        return <Route exact path={prop.path} key={key} component={prop.component} />;
                    } else {
                        return <Route path={prop.path} key={key} component={prop.component} />;
                    }

                })}
                </Switch>
                </Wrapper>
            </Router>
        )
    }
}
