import React, { Component } from 'react'

export default class BgLoader extends Component {

    constructor(props){
        super(props)

        this.controller = new AbortController();

    }

    componentDidMount() {

        this.updatingTheData()

    }

    componentDidUpdate(nextProps) {

        if(nextProps.location.pathname !== this.props.location.pathname) {

            console.log("location changed")
            this.updatingTheData()

        }

    }

    componentWillUnmount(){
        this.controller.abort();
    }

    async updatingTheData(){
       
        let oldStamp = (window.sessionStorage.getItem('stamp')) ? window.sessionStorage.getItem('stamp') : false;

        let endpoints = [
            {url: "https://beta.riccione.com/wp-json/wp/v2/job?per_page=100", loc: "jobs-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/filter?per_page=100", loc: "filters-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/team?per_page=100", loc: "team-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/testimonial/?per_page=100",loc: "testi-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/pages?slug=home&_fields=acf", loc: "home-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/pages?slug=about-us&_fields=acf", loc: "about-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/pages?slug=who-is-hiring&_fields=acf", loc: "who-is-hiring-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/pages?slug=the-right-fit&_fields=acf", loc: "the-right-fit-data"},
            {url: "https://beta.riccione.com/wp-json/wp/v2/pages?slug=find-jobs&_fields=acf", loc: "find-jobs-data"},
            {url: "https://beta.riccione.com/wp-json/acf/v3/options/options", loc: "options"}
        ]

        const since = Date.now() - oldStamp
        const norm = Math.floor(since/1000)

        if(norm >= 1800) {

            for(let i = 0; i < endpoints.length; i++) {

                let { url, loc } = endpoints[i]
                //let saved = (window.localStorage.getItem(loc)) ? true : false

                if(window.navigator.onLine) {

                    fetch(url, {
                        signal: this.controller.signal
                    })
                    .then(res => res.json())
                    .then(res => {

                        let data

                        if(loc === "filters-data") {

                            let structure = {
                                "location": [],  //ID 29
                                "title": [],     //ID 30
                                "salery": []     //ID 28
                            }

                            res.map((el) => {

                                if(el.parent === 29 && el.count !== 0)
                                    structure.location.push(el) 

                                if(el.parent === 30 && el.count !== 0)
                                    structure.title.push(el)

                                if(el.parent === 28 && el.count !== 0)
                                    structure.salery.push(el)

                                return true

                            })

                            structure.salery.sort(function(a, b){
                                return parseInt(a.slug) - parseInt(b.slug)
                            })

                            data = structure

                        } else if(url.includes("_fields=acf")) {

                            data = res[0]

                        } else {

                            data = res

                        }
        
                        window.localStorage.setItem(loc, JSON.stringify(data))
                        console.log("Loaded:" + loc)
        
                    })
                    .catch(error => {
                        if (error.name === 'AbortError') return; // expected, this is the abort, so just return
                        throw error; // must be some other error, handle however you normally would
                    })

                } else {

                    console.log("Skipping", loc)

                }
            }

            window.sessionStorage.setItem("stamp", Date.now())

        } else {

            console.log("Refresh not required.")

        }

    }


    render() {

        return (<React.Fragment />)
    }
}
