module.exports = {
    defaultTitle: 'Riccione Resources, Inc > Putting the Right People Together',
    logo: '/logo192.png',
    author: 'Nick Riccione',
    url: 'https://riccione.com/',
    companyName: 'Riccione Resources, Inc',
    legalName: 'Nick Riccione',
    defaultDescription:
      "We're a locally grown recruiting firm in Dallas with deep roots in the software engineering field.",
    socialLinks: {
      twitter: 'https://twitter.com/RiccioneR',
      linkedin: 'https://www.linkedin.com/company/riccione-resources/',
      facebook: "https://www.facebook.com/Riccione-Resources-Inc-207347306208/?eid=ARBi8ASxyC3x5pDXln3v0fjKI1CGaxLPbkFREy-eVAAVfELn0yQ-UMtOtwX9PPxqpxYh_ABbivmGE86s"
    },
    googleAnalyticsID: '',
    themeColor: '#000000',
    backgroundColor: '#44555f',
    siteRss: '/rss.xml',
    social: {
      facebook: '207347306208',
      twitter: '@RiccioneR',
    },
    address: {
      city: 'Dallas',
      region: 'Texas',
      country: 'US',
      zipCode: '75252',
    },
    contact: {
      email: 'info@riccione.com',
      phone: '972-407-1900',
    },
    //foundingDate: '2016',
  }
