import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    // InstapaperShareButton,
    LinkedinShareButton,
    // LivejournalShareButton,
    // TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    // WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    //TelegramIcon,
    WhatsappIcon,
    //WorkplaceIcon,
    //LivejournalIcon,
    //FacebookMessengerIcon
} from "react-share";

export default function Social(props) {

    const url = "https://riccione.com"

    let { city, title, slug } = props

    let s = (slug) ? url + "/job/" + slug : url

    title = title.replace(/(<([^>]+)>)/ig,"");

    let twittertags = ["Riccione", "TechJob", "Texas"]
    let subject = `Email regarding the role: ${title}`
    let emailBody = `
    I would like to share this exciting opportunity with you. For more information and application see here:
    ${title}
    `

    if(city)
        twittertags.push(city)
 
    return (
        <div className="social">
            <EmailShareButton
                subject={subject}
                body={emailBody}
                separator=" - "
                className="socialButton"
                url={s}
            >
                <EmailIcon />
            </EmailShareButton>
            <FacebookShareButton 
                quote={title}
                hashtag={"#TechJob"}
                className="socialButton"
                url={s}
            >
                <FacebookIcon />
            </FacebookShareButton> 
            
            <LinkedinShareButton
                title={title}
                summery="A great role brought to you by Riccione Resources"
                source="riccione.com"
                className="socialButton"
                url={s}
            >
                <LinkedinIcon />
            </LinkedinShareButton> 
            
            <WhatsappShareButton
                title={title}
                url={s}
                className="socialButton"
                separator=" - "
            >
                <WhatsappIcon />    
            </WhatsappShareButton> 
            
            <TwitterShareButton 
                className="socialButton"
                hashtags={twittertags}
                via="RiccioneR"
                title={title}
                url={s}
            >
                <TwitterIcon />
            </TwitterShareButton> 
        </div>
    )
}
