import React from 'react'
import clickimg from '../images/click.svg'

export default function LinkedInLink(props) {


    let el = ""

    let { link, color } = props

        el = (
                <a href={link} className={"is-block has-text-center linkedInLink text-"+color??"bluedk"} target="_blank" rel="noopener noreferrer">
                        Click here and join us on LinkedIn! 
                        <img src={clickimg} alt="Join us on linked in" />
                </a>
        )

    return el
}
