import React, { Component } from 'react'
import Nav from './Nav'


export default class Header extends Component {

    render() {
        return (
            <React.Fragment>
            <header>
                <Nav />
            </header>
            </React.Fragment>
        )
    }
}
