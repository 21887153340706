import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Nav extends Component {
    
    copyToClipboard(str){
        console.log("I was clicked", str)
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        
        let tag = document.querySelector(".copyurl .tag")
        tag.classList.toggle("is-hidden")
        setTimeout(() => tag.classList.toggle("is-hidden"), 1800)
    }
    
    render() {

        return (
            <nav className="navbar is-dark is-fixed-top" role="navigation" aria-label="main navigation">

                <div className="navbar-brand">
                    <div className="copyurl is-pulled-left" onClick={() => this.copyToClipboard(window.location.href)}>
                        <i className="fas fa-share-alt"></i><span className="tag is-success is-hidden">Page Link Copied!</span>
                    </div>
                    <Link className="navbar-item" to="/">
                    <h1 className="title is-4">Riccione Resources, Inc.</h1>
                    </Link>

                    <button className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="headerNav">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    </button>
                </div>

                
                <div id="headerNav" className="navbar-menu">
                 <div className="container">
                    <div className="navbar-start">
                        <Link to="/about-us/" className="navbar-item">
                            About Us
                        </Link>
                        <Link to="/find-jobs/" className="navbar-item">
                            Find Jobs
                        </Link>
                        <Link to="/the-right-fit/" className="navbar-item">
                            Candidates
                        </Link>
                        <Link to="/whos-hiring/" className="navbar-item">
                            Clients
                        </Link>
                        <Link to="/news/" className="navbar-item">
                            News
                        </Link>
                        <Link to="/contact-us/" className="navbar-item">
                            Contact Us
                        </Link>
                    </div>
                </div>
                

                <div className="navbar-end">
 
                </div>
            </div>

        </nav>
        )
    }
}
