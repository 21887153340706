import React from 'react'
import Img from 'react-image'
import Loading from './Loading'

export default function Image(props) {

    let { title, src, classes } = props

    return (
        <Img 
            src={src}
            alt={title}
            title={title}
            className={classes}
            loader={<Loading />}
        />
    )
}
