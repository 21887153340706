import React from 'react'
import Loading from '../elements/Loading'

export default function Filters(props) {

    let { filters, change, setFilters, handleDelete } = props 
    let res

    if(filters) {

        let Location, Job, Salery, Item, Type

        Location = filters.location.map((el,i) => {

            if(el.parent === 29 && el.name !== 'Remote') {
                return (<li key={i} className="option" data-value={el.id} dangerouslySetInnerHTML={{__html: el.name }}  onClick={change} data-color="warning"/>)
            } else {
                return ""
            }
        })

        Job = filters.title.map((el,i) => {

            if(el.parent === 30) {
                return (<li key={i} className="option" data-value={el.id} dangerouslySetInnerHTML={{__html: el.name }} onClick={change} data-color="info"/>)
            } else {
                return ""
            }
        })

        Salery = filters.salery.map((el,i) => {

            if(el.parent === 28) {
                return (<li key={i} className="option" data-value={el.id} dangerouslySetInnerHTML={{__html: el.name }} onClick={change} data-color="success"/>)
            } else {
                return ""
            }
        })

        Type = filters.location.map((el,i) => {
            if(el.name === 'Remote') {
                return <li key={i} className="option" data-value={el.id} dangerouslySetInnerHTML={{__html: el.name }} onClick={change} data-color="success"/>
            } else {
                return ""
            }
        })

        Item = setFilters.map((el, i) => {    

            let r

            let col = 'warning'

            r = filters.location.filter(obj => {
                return obj.id === parseInt(el)
            })

            if(r.length === 0) {

                r = filters.title.filter(obj => {
                    return obj.id === parseInt(el)
                    
                })  

                col = "info"
            }

            if(r.length === 0) {

                r = filters.salery.filter(obj => {
                    return obj.id === parseInt(el)
                })  

                col = "success"
            }

            r = r[0]

            let res = (

                <div key={i} className="control" id={"tag-"+parseInt(el)}>
                    <div className="tags has-addons">
                    <span className={"tag has-background-"+col+" has-text-light"} dangerouslySetInnerHTML={{__html: r.name}}></span>
                    <a href="/" className="tag is-delete" onClick={handleDelete} data-tagid={parseInt(el)}><span className="is-sr-only">Remove Filter Tag</span></a>
                    </div>
                </div>

            )

            return res

        })

        let handleOpen = (e) => {

            e.preventDefault()

            let el      = e.currentTarget
            let active  = document.getElementsByClassName('active-filter')[0]
            let open    = document.getElementsByClassName('open')[0]
            let targ    = el.dataset['target']
            let handle  = el.childNodes[0]
            let target  = document.getElementById('filters-'+targ)

            if(active) {

                if(el.classList.contains('open')) {
                    el.classList.toggle('open')
                    target.classList.add('is-hidden')
                } else {
                    if(open){
                        open.classList.remove('open')
                        open.childNodes[0].classList.remove('fa-caret-up')
                        open.childNodes[0].classList.add('fa-caret-down')
                    }
                    active.classList.add('is-hidden')
                    active.classList.toggle('active-filter')
                    el.classList.toggle('open')
                    target.classList.toggle('active-filter')
                    target.classList.remove('is-hidden')
                }
            
            } else {

                el.classList.toggle('open')
                target.classList.toggle('active-filter')
                target.classList.remove('is-hidden')

            }

            handle.classList.toggle("fa-caret-down")
            handle.classList.toggle("fa-caret-up")
            
        }

        let filterTitle = (setFilters.length === 0) ? "" : "Active Filters"

        res = (

            <div className="filters is-relative">
                <div className="content filter-header">
                    <h5>{filterTitle}</h5>
                    
                    <div className="field is-grouped is-grouped-multiline set-filters">
                        {Item}
                    </div>
                </div>
                <div className="columns is-mobile is-multiline is-align-content-center">
                    <div className="column has-text-centered is-half-mobile">
                        <a href="/find-jobs" data-target='location' onClick={handleOpen}>
                        <i className="fa fa-caret-down" aria-hidden="true"></i> Location
                        </a>
                    </div>
                    <div className="column has-text-centered is-half-mobile">
                        <a href="/find-jobs" data-target='title' onClick={handleOpen}>
                        <i className="fa fa-caret-down" aria-hidden="true"></i> Title
                        </a>
                    </div>
                    <div className="column has-text-centered is-half-mobile">
                        <a href="/find-jobs" data-target='salery' onClick={handleOpen}>
                        <i className="fa fa-caret-down" aria-hidden="true"></i> Salary
                        </a>
                    </div>
                    <div className="column has-text-centered is-half-mobile">
                        <a href="/find-jobs" data-target='type' onClick={handleOpen}>
                        <i className="fa fa-caret-down" aria-hidden="true"></i> Workplace Type
                        </a>
                    </div>
                </div>
                <div className="columns is-mobile is-aligned-content-center" id="filters">
                    <div className='column is-full is-hidden' id="filters-location">
                        <ul className="menu-list" id="location">
                            {Location}
                        </ul>
                    </div>
                    <div className='column is-full is-hidden' id="filters-title">
                        <ul className="menu-list" id="title">
                            {Job}
                        </ul>
                    </div>
                    <div className='column is-full is-hidden' id="filters-salery">
                        <ul className="menu-list" id="salery">
                            {Salery}
                        </ul>
                    </div>
                    <div className='column is-full is-hidden' id="filters-type">
                        <ul className="menu-list" id="type">
                           {Type}
                        </ul>
                    </div>
                </div>
            </div>
        )

    } else {

        res = (<Loading />)

    }

    return (
        <div>
            {res}
        </div>
    )
}
