import ReactGA from 'react-ga';

export default async function pageUpdate(url, store) {

    ReactGA.initialize('UA-63533940-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    let controller = new AbortController();

    controller.signal.addEventListener('abort', () => controller.abort());

    let r = await fetch(url, {
        signal: controller.signal
    })
    .then(res => res.json())
    .then(res => {

        let r = res

        if(url.includes("_fields=acf"))
            r = res[0]

        window.localStorage.setItem(store, JSON.stringify(r))
        console.log(store, "- page refreshed");
        return r

    })
    .catch(error => {
        if (error.name === 'AbortError') return; // expected, this is the abort, so just return
        throw error; // must be some other error, handle however you normally would
    })

    return r

}

