import React from 'react'
import Helmet from 'react-helmet'
import Thumbnail from '../images/logo192-min.jpeg'

import {
  url,
  defaultTitle,
  defaultDescription,
  social,
  socialLinks,
  address,
  contact,
  legalName,
  companyName,
  author,
} from '../data/config'


export const StructuredDatajobPosting = (type, title, description, date, companyName) => {
  
  title = title.replace(/(<([^>]+)>)/ig,"");
  description = description.replace(/(<([^>]+)>)/ig,"");

  return(`<script type="application/ld+json">
{
  "@context" : "https://schema.org/",
  "@type" : "${type}",
  "title" : "${title}",
  "description" : "${description}",
  "identifier": {
    "@type": "PropertyValue",
    "name": "${companyName}"
  },
  "datePosted" : "${date}",
  "hiringOrganization" : {
    "@type" : "Organization",
    "name" : "${companyName}",
    "sameAs" : "https://riccione.com",
    "logo" : "https://riccione.com/logo192.png"
  },
  "jobLocation": {
    "@type": "Place",
      "address": {
      "@type": "PostalAddress",
      "addressRegion": "TX",
      "addressCountry": "US"
    }
  }
}
</script>`)}

export const SEO = ({
  title,
  type,
  description,
  articleBody,
  datePublished,
  dateModified,
  cover,
  location = window.location.pathname.substring(1),
  readTime,
  date,
  children,
}) => {

  //const [count, setCount] = useState(0);

  const structuredDataArticle = `{
		"@context": "http://schema.org",
		"@type": "${type}",
		"mainEntityOfPage": {
			"@type": "WebPage",
			"@id": "https://google.com/article"
		},
		"headline": "${description}",
		"image": "https://riccione.com/logo512.png",
		"datePublished": "${datePublished}",
		"dateModified": "${dateModified}",
		"author": {
			"@type": "Person",
			"name": "${author}"
		},
		"articleBody": "${articleBody}",
		"publisher": {
			"@type": "Organization",
			"name": "${author}",
			"logo": {
				"@type": "ImageObject",
				"url": "https://riccione.com/logo192.png"
			}
		},
		"description": "${description}",
		"url": "${url}${location}"
	}`

  const structuredDataOrganization = `{
		"@context": "http://schema.org",
		"@type": "${type}",
		"legalName": "${companyName}",
		"url": "${url}",
		"logo": "https://riccione.com/logo192.png",
		"founders": [{
			"@type": "Person",
			"name": "${legalName}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"telephone": "${contact.phone}",
			"contactType": "customer service"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.zipCode}"
		},
		"sameAs": [
			"${socialLinks.twitter}",
			"${socialLinks.linkedin}",
			"${socialLinks.facebook}"
		]
    }`

  const structuredDatajobPosting = `
  {
    "@context" : "https://schema.org/",
    "@type" : "${type}",
    "title" : "${title}",
    "description" : "${description}",
    "identifier": {
      "@type": "PropertyValue",
      "name": "${companyName}"
    },
    "datePosted" : "${date}",
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : "${companyName}",
      "sameAs" : "https://riccione.com",
      "logo" : "https://riccione.com/logo192.png"
    },
    "jobLocation": {
      "@type": "Place",
        "address": {
        "@type": "PostalAddress",
        "addressRegion": "TX",
        "addressCountry": "US"
      }
    }
  }
  `

  const webSite = `{
    "@context": "http://schema.org",
    "@type": "WebSite",
    "copyrightYear": "2020",
    "name": "${companyName}",
    "copyrightHolder": {
        "@type": "Organization",
        "name": "${companyName}"
    },
    "sourceOrganization": {
        "@type": "Organization",
        "foundingDate": 2020,
        "legalName": "${companyName}",
        "name": "${companyName}",
        "url": "${url}",
        "logo": "https://riccione.com/logo192.png",
        "sameAs": [
            "https://www.facebook.com/Riccione-Resources-Inc-207347306208/?eid=ARBi8ASxyC3x5pDXln3v0fjKI1CGaxLPbkFREy-eVAAVfELn0yQ-UMtOtwX9PPxqpxYh_ABbivmGE86s"
        ]
    },
    "thumbnailUrl": "https://riccione.com/logo512.png",
    "alternateName": "${title}",
    "description": "${description}",
    "url": "${url}${location}",
    "mainEntityOfPage": "${url}",
    "isAccessibleForFree": "yes"
    }
` 

  let sData
  let ogType

  if(type === 'NewsArticle') {

    sData = structuredDataArticle
    ogType = 'NewsArticle'

  } else if(type === "JobPosting") {

    sData = structuredDatajobPosting
    ogType = 'JobPosting'

  } else {

    sData = structuredDataOrganization
    ogType = 'website'

  }

  let thumb = Thumbnail.substring(1)

  //let urll = window.location.href.split("/").slice(2)

  // let canical = "https://" + window.location.host + window.location.pathname
  
  // if(urll.length <= 2) {

  //   canical = canical.replace(/\/?$/, '/');

  // }

  
  return (
    <Helmet>
      <meta name="description" content={description || defaultDescription} />
      <meta
        name="image"
        content={cover ? `${url}${cover}` : `${url}${thumb}`}
      />
      <link rel="preconnect" href="https://storage.googleapis.com" />
      <link rel="preconnect" href="https://riccione.com" />
      <link rel="preconnect" href="https://kit-free.fontawesome.com" />
      
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={social.twitter} />
      <meta property="og:url" content={`${url}${location}`} />
      <meta
        property="og:type"
        content={ogType}
      />
      <meta
        property="og:title"
        content={title ? `Riccione Resources, Inc > ${title}` : defaultTitle}
      />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta
        property="og:image"
        content={cover ? `${url}${cover}` : `${url}${thumb}`}
      />
      <meta property="fb:app_id" content={social.facebook} />

      
      {/* <meta name="twitter:creator" content={social.twitter} /> */
      /* <meta
        name="twitter:title"
        content={title ? `Riccione | ${title}` : defaultTitle}
      /> */
      /* <meta
        name="twitter:description"
        content={description || defaultDescription}
      />
      <meta
        name="twitter:image"
        content={cover ? `${url}${cover}` : `${url}${thumb}`}
      /> */}
      <script type="application/ld+json">
        {sData}
      </script>
      <script type="application/ld+json">
        {webSite}
      </script>
      {children}
      <link rel="publisher" href={socialLinks.google} />
      <title>{title ? `Riccione Resources | ${title}` : defaultTitle}</title>
      {type === 'NewsArticle' && (
        <meta name="twitter:label1" value="Reading time" />
      )}
      {type === 'NewsArticle' && (
        <meta name="twitter:data1" value={`${readTime} min read`} />
      )}
      {type === 'NewsArticle' && (
        <meta name="author" content="Riccione" data-react-helmet="true" />
      )}
      {type === 'NewsArticle' && (
        <meta
          name="article:published_time"
          content={datePublished}
          data-react-helmet="true"
        />
      )}
      <html lang="en" dir="ltr" />
    </Helmet>
  )
}