import React from 'react'
import { Forms } from '../elements/Forms'
import { SEO } from '../components/SEO' 
import Img from '../elements/Image'
import largeLogo from '../images/logolrland.svg'

export default function Apply(props) {

    let jobs = (window.localStorage.getItem('jobs-data')) ? JSON.parse(window.localStorage.getItem('jobs-data')) : false;
    let team = (window.localStorage.getItem('team-data')) ? JSON.parse(window.localStorage.getItem('team-data')) : false;
    let url_string = window.location.href
    let url = new URL(url_string);
    var jid = url.searchParams.get("job");
    var tid = parseInt(url.searchParams.get("s"));
    let temail = false;

    if(jobs) {
        var job = jobs.filter(obj => {
            return jid === obj.acf.job_id
        })
    } else {
        job = false
    }

    
    if(tid) {
        temail = team.filter(obj => {
            return tid === obj.id
        })[0]
    } else {
        temail = false
    }

    let teamID = (temail) ? temail.acf.contact_email : false;

    let intro

    if(job.length > 0) {

        intro = (
            <React.Fragment>
                    <h3 dangerouslySetInnerHTML={{__html: job[0].title.rendered}} />
                    <div dangerouslySetInnerHTML={{__html: job[0].acf.job_description}} />
            </React.Fragment>
        )

    } else {

        intro = (
            <h1>Submit Your Resume</h1>
        )

    }

    return (
        <React.Fragment>
        <SEO type="Organization" title="Apply for the Position" />
        <div className="content is-large front-head">
            <div className='container'>
                <Img src={largeLogo} title="Riccione Recruting" style={{height: "100px"}} />
            </div>
        </div>
        <section className="section apply">
            <div className="container">
                <div className="content">
                    {intro}
                </div>
                <Forms jobID={jid} teamID={teamID} {...props} />
            </div>
       </section>
       </React.Fragment>
    )
}
