import React, { Component } from 'react'
import { SEO } from '../components/SEO'
import Social from '../elements/Social'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
export default class Resume extends Component {

    constructor(props){
        super(props)

        this.controller = new AbortController()

        this.state = {
            slug: this.props.location.pathname.replace("/job/", ""),
            item: false,
            team: false,
            goBack: props.history.goBack,
        }

        let jobs = (window.localStorage.getItem('jobs-data')) ? JSON.parse(window.localStorage.getItem('jobs-data')) : false;
        let team = (window.localStorage.getItem('team-data')) ? JSON.parse(window.localStorage.getItem('team-data')) : false;

        if(jobs) {

            let job = jobs.filter(obj => {
                return obj.slug === this.state.slug
            })

            this.state.item = (job[0]) ? job[0] : false
        }

        if(team) {

            let staff = false

            if(this.state.item) {
                staff = team.filter(obj => {
                    return obj.link === this.state.item.acf.team_member
                })
            }

            this.state.team = (staff) ? staff[0] : false

        }

    }

    componentDidMount() {

        let data = "https://beta.riccione.com/wp-json/wp/v2/job?per_page=100"
        let team = "https://beta.riccione.com/wp-json/wp/v2/team?per_page=100"

        fetch(data, {
            signal: this.controller.signal
        })
        .then(res => res.json())
        .then(res => {

            let job = res.filter(obj => {
                return obj.slug === this.state.slug
            })

            this.setState({item: (job[0]) ? job[0] : "404"})

            window.localStorage.setItem('jobs-data', JSON.stringify(res))

            fetch(team, {
                signal: this.controller.signal
            })
            .then(tres => tres.json())
            .then(tres => {

                let team = "404"

                if(this.state.item !== "404") {
                    team = tres.filter(obj => {
                        return obj.link === this.state.item.acf.team_member
                    })

                    window.localStorage.setItem('team-data', JSON.stringify(tres))
                }

                this.setState({team: (team[0]) ? team[0] : "404"})

                

            }).catch(error => {
                if (error.name === 'AbortError') return; // expected, this is the abort, so just return
                throw error; // must be some other error, handle however you normally would
            })

        })

    }

    componentWillUnmount(){
        this.controller.abort()
    }

    render() {

        let data

        if(this.state.item === false || this.state.team === false) {

            data = (

                <section className="section">
                    <div className="jobs-area">
                        <div className="container content has-text-centered">
                            <h3>Welcome to Riccione.com - We are loading your job information...</h3>
                            <progress className="progress is-small is-primary" max="100">15%</progress>
                        </div>
                    </div>
                </section>

            )

        } else if(this.state.item === "404") {

            data = (<Redirect to="/404/" />)

        } else {

            let description = this.state.item.acf.job_description.replace(/(<([^>]+)>)/ig,"");

            let staff = {
                id: this.state.team.id,
                name: (this.state.team.title) ? this.state.team.title.rendered : "",
                email: (this.state.team.acf) ? this.state.team.acf.contact_email : ""
            }


            let city = (this.state.item.acf.city) ? `"addressLocality" : "${this.state.item.acf.city}",` : ""
            let type = (this.state.item.acf.job_type) ? `"employmentType" : "${this.state.item.acf.job_type}",` : ""
            let conSale = ""
            let builtTitle = this.state.item.acf.city + this.state.item.acf.state_code

                if(this.state.item.acf.salery && this.state.item.acf.salery !== "0"){

                    conSale = `, "baseSalary": {
                        "@type": "MonetaryAmount",
                        "currency": "USD",
                        "value": {
                          "@type": "QuantitativeValue",
                          "value": ${this.state.item.acf.salery},
                          "unitText": "${this.state.item.acf.salary_unit}"
                        }
                      }`

                }

            let sd = `
                {
                  "@context" : "https://schema.org/",
                  "@type" : "JobPosting",
                  "title" : "${this.state.item.title.rendered + " - " + builtTitle}",
                  "description" : "${description}",
                  "identifier": {
                    "@type": "PropertyValue",
                    "name": "Riccione Resources, Inc"
                  },
                  "datePosted" : "${this.state.item.date}",
                  ${type}
                  "hiringOrganization" : {
                    "@type" : "Organization",
                    "name" : "Riccione Resources, Inc",
                    "sameAs" : "https://riccione.com",
                    "logo" : "https://riccione.com/logo192.png"
                  },
                  "jobLocation": {
                    "@type": "Place",
                      "address": {
                      "@type": "PostalAddress",
                      ${city}
                      "addressRegion": "${this.state.item.acf.state_code}",
                      "addressCountry": "US"
                    }
                  }
                  ${conSale}
                }
                `

            data = (
                <React.Fragment>
                    <SEO 
                        type="Organization" 
                        title={this.state.item.title.rendered} 
                        description={description}
                        date={this.state.item.date} />
                    <Helmet>
                            <script type="application/ld+json">
                                {sd}
                            </script>
                    </Helmet>
                    <section className="section single-jobs-page">
                        <div className="bartop green" />
                        <div className="jobs-area">
                            <div className="container">
                                <div className="content">
                                <h1 dangerouslySetInnerHTML={{__html: this.state.item.title.rendered}} />
                                <div dangerouslySetInnerHTML={{__html: this.state.item.acf.job_description}} />
                                <br />
                                <div className="card">
                                    <div className="card-content">
                                    <p><small><strong>Contact: </strong><a href={"mailto:"+staff.email}>{staff.name}</a></small></p>
                                    </div>
                                </div>
                                <Social city={this.state.item.acf.city} url={window.location.href} title={this.state.item.title.rendered} />
                                <hr />
                                <div dangerouslySetInnerHTML={{__html: this.state.item.content.rendered}} />
                                <Social city={this.state.item.acf.city} url={window.location.href} title={this.state.item.title.rendered} />
                                <div className="apply-buttons">
                                        <Link to={"/apply-now/?job="+this.state.item.acf.job_id+"&s="+staff.id} className="button">Apply</Link>
                                        <button className="button" onClick={this.state.goBack}>Go Back</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            )

        }

        return data
    }
}
