import React, { Component } from 'react'
import {SEO} from '../components/SEO'
import { Link } from 'react-router-dom'

export default class NoPage extends Component {
    render() {
        return (
            <React.Fragment>
                <SEO title="404: Page Not Found" type="Organization" />
                <div className="content has-text-centered nopage">
                    <div className="container section">
                        <h1>404: Oh No!</h1>
                        <p>Unfortunatly we couldn't find the page you were looking for!</p>
                        <p>You can find our Job Listings here: <Link to="/find-jobs">The Jobs Page</Link></p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
