import React, { Component } from 'react'
import { SEO } from '../components/SEO'
import Loading from '../elements/Loading'
import Img from 'react-image'
import largeLogo from "../images/logolr.svg"
import { Link, Redirect } from "react-router-dom"

export class PageTemplate extends Component {

    constructor(props) {
        super(props)

        console.log(props)

        let { type } = props
        const tempType = (type === "pages") ? 'Organization' : 'NewsArticle'

        this.controller = new AbortController()

        let loc = this.props.location.pathname

        if(loc.includes("news/")) {
            loc = loc.replace("news/", "")
            type = "posts"
        } else {
            loc = loc.replace("/", "")
        }

        this.state = {
            url: "https://beta.riccione.com/wp-json/wp/v2/"+type+"?slug=",
            location: loc,
            title: false,
            content: false,
            link: false,
            media: null,
            template: type,
            key: this.props.location.key,
            seo: tempType
        }

        let pageData = (window.localStorage.getItem(type+'-'+this.state.location)) ? JSON.parse(window.localStorage.getItem(type+'-'+this.state.location)) : false;

        if(pageData) {

            this.state.title         = pageData.title.rendered
            this.state.content       = pageData.content.rendered
            this.state.media         = (pageData.media) ? pageData.media : null
            
        }

    }

    componentDidMount() {

        let fetchThis = this.state.url + this.state.location + "&_embed"

        console.log(fetchThis)

        fetch(fetchThis, {
            signal: this.controller.signal
        })
        .then(res => res.json())
        .then(res => {

            let newState = this.state

            if(res.length > 0 ) { 

                window.localStorage.setItem(this.state.template+'-'+this.state.location, JSON.stringify(res[0]));

                newState.title = res[0].title.rendered
                newState.content = res[0].content.rendered
                newState.media = (res[0]['_embedded']['wp:featuredmedia']) ? res[0]['_embedded']['wp:featuredmedia'][0].media_details : false
                newState.key = this.props.location.key
                newState.link = res[0].acf.link

                console.log(res[0].acf.link)

                this.setState(newState)
            
           } else {

                newState.title = "404"
                newState.content = "Ain't no sunshine when she's gone"
                newState.key = "404"

                this.setState(newState)
 
           }

        }).catch(error => {
            if (error.name === 'AbortError') return; // expected, this is the abort, so just return
            throw error; // must be some other error, handle however you normally would
        })

    }

    componentDidUpdate(nextProps) {
        
        if(nextProps.location.pathname !== this.props.location.pathname) {

            let pathname = this.props.location.pathname.replace("/", "")

            let fetchThis = this.state.url + pathname + "&_embed"     

            let pageData = (window.localStorage.getItem(this.state.template+'-'+pathname)) ? JSON.parse(window.localStorage.getItem(this.state.template+'-'+pathname)) : false;

            if(pageData) {

                let media = (pageData.media) ? pageData.media : null

                this.setState({

                    title: pageData.title.rendered,
                    content: pageData.content.rendered,
                    media: media,
                    key: nextProps.location.key

                })
                
            }

            fetch(fetchThis, {
                signal: this.controller.signal
            })
            .then(res => res.json())
            .then(res => {

                let newState = this.state

                if(res.length > 0 ) { 

                    window.localStorage.setItem(this.state.template+'-'+pathname, JSON.stringify(res[0]));

                    newState.title = res[0].title.rendered
                    newState.content = res[0].content.rendered
                    newState.location = pathname
                    newState.media = (res[0]['_embedded']['wp:featuredmedia']) ? res[0]['_embedded']['wp:featuredmedia'][0].media_details : false
                    newState.key = nextProps.location.key
                    newState.link = res[0].acf.link

                    this.setState(newState)

                } else {

                    newState.title = "404"
                    newState.content = "Ain't no sunshine when she's gone"
                    
                    this.setState(newState)
                }
                
            }).catch(error => {
                if (error.name === 'AbortError') return; // expected, this is the abort, so just return
                throw error; // must be some other error, handle however you normally would
            })

        }
    }

    contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        e.preventDefault();
        
        if(targetLink.href.indexOf(window.location.origin) > -1) {
            this.props.history.push(e.target.href.replace(window.location.origin, ""))
        }

    }

    componentWillUnmount(){
        this.controller.abort();
    }

    render() {
        let ref = (this.state.link && this.state.link.includes("?")) ? "&ref=riccione.com" : "?ref=riccione.com"
        const Image = (this.state.media) ? (<img src={this.state.media.sizes.medium.source_url} width={this.state.media.sizes.medium.width} alt={this.state.title} className="is-pulled-left featured" />) : (<></>)
        const Lnk = (props) => {

            let r
            
            if(this.state.link) {

                r = ( <a href={this.state.link + ref} target="_blank" rel="noopener noreferrer">{props.children}</a> ) 

            } else {

                r = ( <>{props.children}</>)

            }

            return r
        }
        let title = (this.state.title) ? this.state.title : "..."
        
        let content

        if(this.state.title && this.state.title !== "404") {

            content = (
                <div className="content ">
                    <div className="box">
                        <Lnk>{Image}</Lnk>
                        <h1 className="has-text-dark" dangerouslySetInnerHTML={{__html: this.state.title}} />
                        <div className="has-text-dark" dangerouslySetInnerHTML={{__html: this.state.content}} />
                        <div>
                        {this.state.link && 
                            <a href={this.state.link + ref} className="button is-info has-text-white is-pulled-right" target="_blank" rel="noopener noreferrer">Read More</a>
                        }
                        </div>
                    </div>
                </div>
            )

        } else if(this.state.title === "404") { 

            content = (

                <Redirect to="/404" />

            )

        } else {

            content = (
                <section className="section has-text-centered" style={{height: "80vh"}}>
                    <SEO title="Riccione News" type="Organization" />
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <Img src={largeLogo} width="400" alt="Riccione Logo" loader={<Loading />} />
                                <p>Welcome to Riccione Resources!</p>
                                <br />
                                <Loading />
                            </div>
                        </div>
                    </div>
                </section> 
            )

        }

        let loc = this.props.location.pathname

        let chk_news = loc.includes("/news/")

        return (
            <React.Fragment>
            <SEO type="Organization" title={this.state.title} />
            <div className="section single">
            <div className="container">
            <nav className="breadcrumb has-text-dark" aria-label="breadcrumbs">
                <ul>
                    <li><Link to="/" className="has-text-dark">Home</Link></li>
                    {chk_news &&
                    <li><Link to="/news/" className="has-text-dark">News</Link></li>
                    }
                    <li className="is-active"><Link to="/" disabled className="has-text-dark" dangerouslySetInnerHTML={{__html: title}}></Link></li>
                </ul>
            </nav>

                {content}
                
            </div>
            </div>
            </React.Fragment>
        )
    }
}

PageTemplate.defaultProps = {
    type: "pages"
};