document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
  
      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
  
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);
  
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
  
        });
      });
    }


    const aitem = Array.prototype.slice.call(document.querySelectorAll('.navbar-item'), 0);

    aitem.forEach(el => {

      el.addEventListener("click", () => {

          let burger = document.querySelector('.navbar-burger'),
              menu   = document.querySelector('.navbar-menu');

          burger.classList.remove('is-active');
          menu.classList.remove("is-active");

      })

    })


    const as = Array.prototype.slice.call(document.querySelectorAll('.footer a'), 0);

    as.forEach(el => {

      el.addEventListener('click', () => {

        window.scroll(0,0);

      })

    })

});

document.body.classList.add('has-navbar-fixed-top');