import React from 'react'
import img from '../images/logor.svg'
import { Link } from 'react-router-dom'
import ReloadModal from '../components/Reload'
import seal from "../images/sectigo_trust_seal.png"


export default function Footer() {


    let d = new Date();
    let y = d.getFullYear();
    

    return (
        <footer className="footer">
            <div className="container">
                <div className="columns is-mobile is-multiline">
                    <div className="column has-text-centered is-half-mobile">
                        <img src={img} style={{width:"80%", maxWidth:"120px", margin: "0 0 1em"}} alt="logo"/>
                        <a href="https://sectigo.com/" target="_blank" rel="noopener noreferrer">
                            <img src={seal} alt="Sectigo Trust Seal" width="83"/>
                        </a>
                    </div>
                    <div className="column is-half-mobile">
                        <ul>
                            <li><h4 className="title is-4 text-yellow">Call us</h4>
                                <a className="has-text-white" href="tel:972-407-1900">972-407-1900</a>
                            </li>
                            <li>
                                <h4 className="title is-4 text-yellow">Email us</h4>
                                <a className="has-text-white" href="mailto:info@riccione.com">info@riccione.com</a>
                            </li>
                        </ul>
                    </div>
                    <div className="column is-half-mobile">
                        <ul>
                            <li><h4 className="title is-4 text-yellow">Find us on</h4>
                                <ul>
                                    <li><a className="has-text-white" href="https://www.facebook.com/Riccione-Resources-Inc-207347306208/?eid=ARBi8ASxyC3x5pDXln3v0fjKI1CGaxLPbkFREy-eVAAVfELn0yQ-UMtOtwX9PPxqpxYh_ABbivmGE86s">Facebook</a></li>
                                    <li><a className="has-text-white" href="https://twitter.com/RiccioneR/">Twitter</a></li>
                                    <li><a className="has-text-white" href="https://www.linkedin.com/company/riccione-resources/">LinkedIn</a></li>
                                    
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="column is-half-mobile">
                        <ul>
                            <li><h4 className="title is-4 text-yellow">Navigation</h4>
                                <ul>
                                    <li><Link className="has-text-white" to="/find-jobs/">Job Board</Link></li>
                                    <li><Link className="has-text-white" to="/apply-now/">Submit Your Resume</Link></li>
                                    <li><Link className="has-text-white" to="/our-passion/?type=client">Client Testimonials</Link></li>
                                    <li><Link className="has-text-white" to="/our-passion/?type=candidate">Candidate Testimonials</Link></li>
                                    <li><Link className="has-text-white" to="/contact-us/">Contact Us</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="column is-full-mobile">
                        <ul>
                            <li>
                                <h4 className="title is-4 text-blue">Riccione Resources</h4>
                                 
                                    <span>18208 Preston Road <br />
                                        Suite D9-390 <br />
                                        Dallas TX 75252
                                    </span>
                              
                            </li>
                            <li className="leagal is-size-7">
                                <Link className="has-text-white" to="/terms-and-conditions">Terms &amp; Conditions </Link> &nbsp;
                                <Link className="has-text-white" to="/privacy-policy">Privacy Policy </Link> &nbsp;
                                <Link className="has-text-white" to="/cookies-policy">Cookies Policy </Link> &nbsp;
                            </li>
                        </ul>
                    </div>
                    <div className="column is-full">
                        <small className="has-text-grey-light">Copyright &copy; {y} Riccione Resources, Inc. All rights reserved. Site by <a className="has-text-grey-light" href="https://rscmedia.co.uk?ref=riccione" rel="noopener noreferrer" target="_blank">RSC Media Ltd.</a></small>
                    </div>
                </div>
            </div>
            <ReloadModal />
        </footer>
    )
}
