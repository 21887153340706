import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { SEO } from '../components/SEO'
import girl from '../images/1-min.jpeg'
import Social from '../elements/Social'
import Filters from '../components/Filters'
import { Link } from 'react-router-dom'
import pageUpdate from '../tools/pageUpdate'
import SearchJobs from '../elements/SearchJobs'
import LinkedInLink from '../elements/LinkedIn'

export default class Jobs extends Component {
    constructor(props) {
        super(props)

        this.controller = new AbortController()

        this.state = {
            items: false,
            team: false,
            filters: false,
            pagetext: false,
            setFilters: [],
            results: [],
            search: false,
            message: "Sorry there are no Job's matching those filters"
        }

        let defaultText = `
        [
            {
              "acf": {
                "box_title": "Find a Path",
                "box_text": "We get it, it’s more than just a job. For some it’s all about growth and challenges. For others it’s more about work/life balance.",
                "main_title": "",
                "main_text": ""
              }
            }
          ]
        `

        let text        = (window.localStorage.getItem('find-jobs-data'))  ? JSON.parse(window.localStorage.getItem('find-jobs-data'))  : JSON.parse(defaultText)[0];
        let jobs        = (window.localStorage.getItem('jobs-data'))       ? JSON.parse(window.localStorage.getItem('jobs-data'))       : false;
        let team        = (window.localStorage.getItem('team-data'))       ? JSON.parse(window.localStorage.getItem('team-data'))       : false;
        let filters     = (window.localStorage.getItem('filters-data'))    ? JSON.parse(window.localStorage.getItem('filters-data'))    : false;
        let setfilters  = (window.localStorage.getItem('setfilters-data')) ? JSON.parse(window.localStorage.getItem('setfilters-data')) : false; 

        this.state.pagetext = text
        
        if(jobs) {
            this.state.items = jobs
        }

        if(team) {
            this.state.team = team
        }

        if(filters) {
            this.state.filters = filters
        }

        if(setfilters) {
            this.state.setFilters = setfilters
        }

    }

    async componentDidMount() {

        this.filtering()

        let loader = document.createElement("span")
        let filterbox = document.querySelector(".searcharea")

        loader.classList.add("button", "is-loading", "updateicon", "is-disabled")

        filterbox.appendChild(loader)

        let data    = "https://beta.riccione.com/wp-json/wp/v2/job?per_page=100"
        let team    = "https://beta.riccione.com/wp-json/wp/v2/team?per_page=100"
        let filters = "https://beta.riccione.com/wp-json/wp/v2/filter?per_page=100"
        let text    = "https://beta.riccione.com/wp-json/wp/v2/pages?slug=find-jobs&_fields=acf"

        if(window.navigator.onLine) {

            fetch(data, {
                signal: this.controller.signal
            })
            .then(res => res.json())
            .then(res => {

                window.localStorage.setItem('jobs-data', JSON.stringify(res))

                fetch(team, {
                    signal: this.controller.signal
                })
                .then(tres => tres.json())
                .then(tres => {
                
                    window.localStorage.setItem('team-data', JSON.stringify(tres))

                    fetch(filters, {
                        signal: this.controller.signal
                    })
                    .then(fres => fres.json())
                    .then(fres => {

                        let structure = {
                            "location": [],  //ID 29
                            "title": [],     //ID 30
                            "salery": []     //ID 28
                        }

                        fres.map((el) => {

                            if(el.parent === 29 && el.count !== 0)
                                structure.location.push(el) 

                            if(el.parent === 30 && el.count !== 0)
                                structure.title.push(el)

                            if(el.parent === 28 && el.count !== 0)
                                structure.salery.push(el)

                            return true

                        })

                        structure.salery.sort(function(a, b){

                            return parseInt(a.slug) - parseInt(b.slug)
                        })

                        window.localStorage.setItem('filters-data', JSON.stringify(structure))

                        this.setState({team: tres, items: res, filters: structure})
                        
                        loader.remove()

                        
                    }).catch(error => {
                        if (error.name === 'AbortError') return; // expected, this is the abort, so just return
                        throw error; // must be some other error, handle however you normally would
                    })
                    
                
                }).catch(error => {
                    if (error.name === 'AbortError') return; // expected, this is the abort, so just return
                    throw error; // must be some other error, handle however you normally would
                })
            
            }).catch(error => {
                if (error.name === 'AbortError') return; // expected, this is the abort, so just return
                throw error; // must be some other error, handle however you normally would
            })

            let url  = text,
            store = "find-jobs-data"

            pageUpdate(url, store, this.controller).then(res => this.setState({data: res})).catch(error => {
                if (error.name === 'AbortError') return; // expected, this is the abort, so just return
                throw error; // must be some other error, handle however you normally would
            })

        }

    }

    handleSearch = (results, search) => {
        let items
        if(search.length > 0) {
            items = this.state.items.filter(item => results.some(res => res.id === item.id))
        } else {
            items = (window.localStorage.getItem('jobs-data')) ? JSON.parse(window.localStorage.getItem('jobs-data')) : false;
        }
        this.setState({results:results, search:search, items:items})
    }

    filtering() {

        // Get the set Filters and name them items
        // Grab the displayed job listings 
        let items = this.state.setFilters
        let options = document.getElementsByClassName("jobitem")
        
        if(items.length > 0){

            // Set a counter and count the total jobs
            let i = 0
            let total = options.length

            //If there are filters set - lets start organising
            // Loop through each job
            for (let op of options) {

                // Get the class list and turn it into an array
                const arr1 = [ ...op.classList ]

                // This part checks the location / salery / title and 
                // gives us a reference of what is what.  

                //gives an array of location ids
                let l = this.state.filters.location.map(el => {

                    let res = false
                    
                    if(items.some(r=> el.id === parseInt(r))) {
                        res = el.id
                    }

                    return res
                // just filter the falses out
                }).filter(el => el !== false)

                //gives an array of salery ids
                let s = this.state.filters.salery.map(el => {

                    let res = false
                    
                    if(items.some(r=> el.id === parseInt(r))) {
                        res = el.id
                    }

                    return res
                // just filter the falses out
                }).filter(el => el !== false)

                //gives an array of title ids
                let t = this.state.filters.title.map(el => {

                    let res = false
                    
                    if(items.some(r=> el.id === parseInt(r))) {
                        res =  el.id
                    }

                    return res
                // just filter the falses out
                }).filter(el => el !== false)
                
                // Setting some vars 
                // Found is the result. 
                let found = false

                // This lets us see how many columns are being used.
                let lcol = (l.length >= 1) ? 1 : 0
                let tcol = (t.length >= 1) ? 1 : 0
                let scol = (s.length >= 1) ? 1 : 0

                let selCols = lcol + tcol + scol

                // If 3 cols we need to have a true result from each. 
                if(selCols === 3) {

                    if(
                        arr1.some(r=> l.indexOf(parseInt(r)) >= 0) &&
                        arr1.some(r=> t.indexOf(parseInt(r)) >= 0) &&
                        arr1.some(r=> s.indexOf(parseInt(r)) >= 0)
                    ) {
                        found = true
                    }

                // If 2 are used we need to have a result from either of the 3 possibilities 
                } else if(selCols === 2) {

                    if(
                        arr1.some(r=> l.indexOf(parseInt(r)) >= 0) &&
                        arr1.some(r=> t.indexOf(parseInt(r)) >= 0)
                    ){

                        found = true

                    } else if(
                        arr1.some(r=> l.indexOf(parseInt(r)) >= 0) &&
                        arr1.some(r=> s.indexOf(parseInt(r)) >= 0)
                    ) {
                        found = true
                    } else if(
                        arr1.some(r=> t.indexOf(parseInt(r)) >= 0) &&
                        arr1.some(r=> s.indexOf(parseInt(r)) >= 0)
                    ) {
                        found = true
                    } else {
                        found = false
                    }

                // Else we match any of them because one column is as easy as 3. 
                } else {

                    if(
                        arr1.some(r=> l.indexOf(parseInt(r)) >= 0) ||
                        arr1.some(r=> t.indexOf(parseInt(r)) >= 0) ||
                        arr1.some(r=> s.indexOf(parseInt(r)) >= 0)
                    ) {
                        found = true
                    } else {
                        found = false
                    }

                }

                // console.log("Counting", count)

                // Here we set to hidden or not depending on if they are visible. 
                if(!found) {
                    op.classList.add("is-hidden")
                    i++
                } else {
                    op.classList.remove("is-hidden")
                }

                if(i === total) {
                    document.getElementById('filterMessage').innerHTML = "<div class='box has-text-centered'><p>No Jobs match the filters selected...</p><p>Trying being less specific</p></div>"
                } else {
                    document.getElementById('filterMessage').innerHTML = ""
                }

            }

        } else {

            // If no filters show EVERYTHING

            for (let op of options) {

                op.classList.remove("is-hidden")

            }

        }

    }

    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }


    componentDidUpdate() {
        this.filtering()
    }

    componentWillUnmount() {
        this.controller.abort()
    }

    handleSelection = (e) => {

            let data = this.state
            let color = e.target.dataset.color

            if(e.target.dataset.value === "all") {


            } else {

            if(data.setFilters.includes(e.target.dataset.value)) {
                let i = data.setFilters.indexOf(e.target.dataset.value)
                data.setFilters.splice(i,1)
            } else {
                data.setFilters.push(e.target.dataset.value)
            }

            e.target.classList.toggle('has-background-'+color)

            }

            this.setState(data)
            window.localStorage.setItem('setfilters-data', JSON.stringify(data.setFilters))

    }


    // DO THIS
    handleDelete = (e) => {

        e.preventDefault()

        let data = this.state
        let id = e.currentTarget.dataset.tagid

        let i = data.setFilters.indexOf(id)
        data.setFilters.splice(i,1)

        let items = document.getElementById("filters").getElementsByClassName("option")

        for(let go of items) {

            let color = go.dataset.color

            if(go.dataset.value === id)
                go.classList.remove("has-background-"+color)

        }

        this.setState(data)
        window.localStorage.setItem('setfilters-data', JSON.stringify(data.setFilters))

    }

    render() {

        let listItems

        let images = {
            girl: {
                backgroundImage: "url("+girl+")",
                backgroundSize: "cover",
                backgroundRepeat: "none",
                backgroundPosition: "center"
            }
        }

        if(this.state.items !== false && this.state.team !== false) {

            listItems = this.state.items.map((el, index) => {

                let title = el.title.rendered.replace(/(<([^>]+)>)/ig,"");
                let description = (el.acf.job_description) ? el.acf.job_description.replace(/(<([^>]+)>)/ig,"") : "";

                //let salery = (el.acf.salery && el.acf.salery !== "0") ? `, "baseSalary" : "${el.acf.salery}",` : ""
                let city = (el.acf.city) ? `"addressLocality" : "${el.acf.city}",` : ""
                let type = (el.acf.job_type) ? `"employmentType" : "${el.acf.job_type}",` : ""
                let conSale = ""

                //console.log(el.acf);

                if(el.acf.salery && el.acf.salery !== "0"){

                    conSale = `, "baseSalary": {
                        "@type": "MonetaryAmount",
                        "currency": "USD",
                        "value": {
                          "@type": "QuantitativeValue",
                          "value": ${el.acf.salery},
                          "unitText": "${el.acf.salary_unit}"
                        }
                      }`

                }


                let s = this.state.team.filter(obj => {
                    return obj.link === el.acf.team_member
                })

                let staff = {};

                if(s[0]) {
                    staff = {
                        id: s[0].id,
                        name: s[0].title.rendered,
                        email: (s[0].acf.contact_email) ? s[0].acf.contact_email : false
                    }
                }

                let bcode = (el.acf.state_code) ? el.acf.state_code : "",
                    bcity = (el.acf.city) ? el.acf.city + ", " : "",
                    stele  = (el.acf.telecommute) ? `"jobLocationType": "TELECOMMUTE"` : "",
                    tele  = (el.acf.telecommute) ? " Remote Position" : ""

                let builtTitle = bcity + bcode

                let sd = `
                {
                  "@context" : "https://schema.org/",
                  "@type" : "JobPosting",
                  "title" : "${title + " - " + builtTitle}",
                  "description" : "${description}",
                  "identifier": {
                    "@type": "PropertyValue",
                    "name": "Riccione Resources, Inc"
                  },
                  "datePosted" : "${el.date}",
                  ${type}
                  "hiringOrganization" : {
                    "@type" : "Organization",
                    "name" : "Riccione Resources, Inc",
                    "sameAs" : "https://riccione.com",
                    "logo" : "https://riccione.com/logo192.png"
                  },
                  "jobLocation": {
                    "@type": "Place",
                      "address": {
                      "@type": "PostalAddress",
                      ${city}
                      "addressRegion": "${bcode}",
                      "addressCountry": "US"
                    }
                  }
                  ${stele}
                  ${conSale}
                }
                `

                let filters = el.filter.join(' ')

                let item = (

                    <li key={index} className={"jobitem my-2 "+filters}  id={el.id}>
                        <Helmet>
                            <script type="application/ld+json">
                                {sd}
                            </script>
                        </Helmet>
                        <div className="content">
                        <Link to={"/job/"+el.slug}>
                            <h3 dangerouslySetInnerHTML={{__html: el.title.rendered}} />
                        </Link>
                        <div dangerouslySetInnerHTML={{__html: el.acf.job_description}} />
                        <div className="card">
                            <div className="card-content meta is-flex">
                                {el.acf.city && 
                                <p><strong>Location:</strong>  {!tele && el.acf.city + ", "}  {!tele && bcode} {tele} </p>
                                }
                                {el.acf.salery !== "0" &&
                                <p><strong>Salary:</strong>  ${this.kFormatter(el.acf.salery)} {el.acf.top_salary && "to $"+this.kFormatter(el.acf.top_salary)} </p>
                                }
                                {staff.email !== false &&
                                <p><strong>Contact: </strong><a href={"mailto:"+staff.email}>{staff.name}</a> </p>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="apply-buttons">
                            <Link to={"/job/"+el.slug} className="button is-info">Read More</Link>
                            <Link to={"/apply-now/?job="+el.acf.job_id+"&s="+staff.id} className={"button is-primary"}>Apply</Link>
                        </div>
                        <Social city={el.acf.city} url={window.location.href+"/job/"+el.slug} title={el.title.rendered} slug={el.slug} />
                        <hr className="yellow-brown" />
                    </li>

                )

                return item
            
            })

            if(listItems.length === 0){
                listItems = (
                    <div className='box has-text-centered'><p>No Jobs match the search query... Please try again or clear the search box</p></div>
                )
            }

        } else {

            listItems = ( <div className="loading"><progress className="progress is-small" max="100">45%</progress></div> )

        }

        let { box_title, box_text } = this.state.pagetext.acf

        return (
            <div className="jobs-area">
                <SEO type="Organization" title={box_title} description={box_text} />
                <div className="container head">
                    <div className="columns is-mobile">
                        <div className="column image" style={images.girl}>
                            
                        </div>
                        <div className="column green is-flex flexcenter">
                            <div className="text content">
                                <h2 dangerouslySetInnerHTML={{__html: box_title}} />
                                <p dangerouslySetInnerHTML={{__html: box_text}} />
                                <LinkedInLink link="https://www.linkedin.com/company/riccione-resources/" color="bluedk"/>
                            </div>
                        </div>
                    </div>
                    <div className="columns bluedk is-mobile searcharea">
                        <div className="column content">
                            <h2>Find the Right Fit</h2>
                            <div className="columns">
                                <div className="column is-full is-align-content-center">
                                    <SearchJobs handleSearch={this.handleSearch}/>
                                </div>
                            </div>
                            <div className="filters">
                                <Filters filters={this.state.filters} setFilters={this.state.setFilters} change={this.handleSelection.bind(this)} handleDelete={this.handleDelete.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section job-results">
                    <div className="container">
                        {window.navigator.onLine !== true &&
                            <div className="notification is-info has-text-centered">
                            You are currently offline - avalible jobs might have changed.
                            </div>
                        }
                        <br />
                        <ul>
                            {listItems}
                            {<li id='filterMessage'></li>}
                        </ul>   
                    </div>
                </div>
            </div>
        )
    }
}
