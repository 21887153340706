import React, { Component } from 'react'


export default class SearchJobs extends Component {

    constructor(props){
        super(props)

        let string = (props.search) ? props.search.replace("?s=", "") : false

        this.state = {
            items: [],
            results: false,
            search: false,
            string: string
        }

        this.timer = null
        this.controller = new AbortController();
        
    }

    search = (e) => {

            if(e) {
                e.persist()
            }

            clearTimeout(this.timer)

            let s = (this.state.string && !e) ? encodeURI(this.state.string) : encodeURI(e.target.value)

            let url = "https://beta.riccione.com/wp-json/wp/v2/search/?per_page=50&subtype=job&_fields=id&search="+s,
                field = document.querySelector(".searchBox"),
                resultsbox = document.querySelector(".job-results")
                
                resultsbox.style.opacity = 0.3
                field.classList.add("is-loading")

            this.timer = setTimeout(() => {

                        if(s) {
                            fetch(url, {signal: this.controller.signal})
                            .then(res => res.json())
                            .then(res => {
                                this.setState({results: res, search: s})
                                this.props.handleSearch(res, s);
                                resultsbox.style.opacity = 1
                                field.classList.remove("is-loading")
                        })

                        } else {
                            this.setState({search: false})
                            this.props.handleSearch([], s);
                            resultsbox.style.opacity = 1
                            field.classList.remove("is-loading")
                        }

                    }, 1500)}

    componentDidMount() {

        if(this.state.string)
            this.search()

        document.querySelector(".searchBox input").value = (this.state.string) ? this.state.string : ""

    }

    handleUpdate(results) {
        this.state({items:results})
    }

    componentWillUnmount()
    {
        this.controller.abort();
    }

    render() {
        return (
            <>
                <div className="field has-addons">
                    <div className="control is-expanded searchBox">
                        <input className="input" type="text" placeholder="Search for Jobs" onChange={this.search}/>
                    </div>
                    <div className="control searchicon">
                        <button className="button is-info">
                            <span className="icon">
                                <i className="fa fa-search"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </>
        )
    }
}
