import React from 'react'

export default function Loading(props) {

    return (
        <div className="columns is-centered">
            <div className={props.wrapClass || "column is-half"}>
                <figure className={props.scale || "image is-5by4"}>
                    <progress className="progress is-small is-primary w-25" max="100">15%</progress>
                </figure>
            </div>
        </div>
    )
}
